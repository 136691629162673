<template>
  <div class="news-center">
    <div class="h">
      <h1 align="center" class="animate__animated animate__bounceInRight">
        NEWS CENTER
      </h1>
      <h2 align="center" class="animate__animated animate__bounceInRight">
        新闻中心
      </h2>
    </div>

    <a-menu :defaultSelectedKeys="path" mode="horizontal">
      <a-menu-item v-for="item in newsMenu" :key="item.id" @click="show(item)">
        {{ item.name }}</a-menu-item
      >
    </a-menu>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: [],
      newsMenu: [
        {
          id: 1,
          name: "行业资讯",
          path: "infomation",
        },
        {
          id: 2,
          name: "企业动态",
          path: "dynamic",
        },
      ],
    };
  },
  methods: {
    show(item) {
      this.path = item.path;
      this.$router.push({ name: item.path });
    },
  },
  mounted() {
    this.$router.push({ name: "infomation" });
  },
};
</script>

<style lang="less">
.news-center {
  position: relative;
  .h {
    width: 100%;
    position: absolute;
    top: -320px;
    h1 {
      color: #ffffff;
      font-size: 72px;
      font-family: Quantum;
      font-weight: 400;
    }
    h2 {
      color: #ffffff;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
  }
  .horizontal {
    width: 1440px;
    margin: 0 auto;
  }
}
</style>
